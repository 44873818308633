<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)" v-if="!loading">
      <div class="row">
        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="layer name"
            rules="required"
          >
            <label for="layer-name" class="form-label">Layer Name</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="layer-name"
              v-model="form.Layer_Name"
              placeholder="Layer Name"
            />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="col-12 mb-3">
          <label for="document" class="form-label">Document</label>
          <div class="d-flex flex-row align-items-center">
            <input
              type="file"
              ref="file"
              class="form-control document me-3"
              id="document"
              @change="processFile"
            />
            <label>{{ documentName }}</label>
          </div>
        </div>

        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="description"
            rules=""
          >
            <label for="description" class="form-label">Description</label>
            <textarea
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="description"
              v-model="form.description"
            ></textarea>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="keywords"
            rules=""
          >
            <label for="keywords" class="form-label">Keywords</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="keywords"
              v-model="form.keywords"
              placeholder="Keywords"
            />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="File Type"
            rules="required"
          >
            <label class="form-label">File Type</label>
            <v-select
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              v-model="form.File_Type"
              :options="options.fileTypes"
              label="Filetype"
              :reduce="(item) => item.Filetype"
            ></v-select>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="Category"
            rules="required"
          >
            <label class="form-label">Category</label>
            <v-select
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              v-model="form.Category"
              :options="options.categories"
              label="Category"
              :reduce="(item) => item.Category"
            ></v-select>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
      </div>
      <button
        v-if="!saving"
        type="submit"
        class="btn btn-primary btn-sm px-2 me-3"
      >
        Update
      </button>
      <button
        v-else
        class="btn btn-primary btn-sm px-2 me-3"
        type="button"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "LayersEditForm",
  props: {
    layer: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      saving: false,
      options: {
        fileTypes: [],
        categories: [],
      },
      form: {
        Layer_Name: "",
        document: "",
        description: "",
        keywords: "",
        File_Type: "",
        Category: "",
      },
      documentName: "",
    };
  },
  mounted() {
    this.getData();
    this.loading = false;
  },
  watch: {
    layer() {
      this.setData();
      this.createFile(this.layer.document);
    },
  },
  methods: {
    setData() {
      this.loading = true;
      this.form = Object.assign({}, this.layer);
      this.form.Category = this.layer.Category;
      this.form.File_Type = this.layer.File_Type;
      this.loading = false;
    },
    async getData() {
      await this.$repository.category.all().then((response) => {
        this.options.categories = response.data.results;
      });

      await this.$repository.fileType.all().then((response) => {
        this.options.fileTypes = response.data.results;
      });
    },
    processFile() {
      this.form.document = this.$refs.file.files[0];
      this.documentName =
        this.$refs.file.files[0].name.split(".")[0].substr(0, 15) +
        "." +
        this.$refs.file.files[0].name.split(".")[1];
    },
    async createFile(fileurl) {
      if (fileurl) {
        //fileurl = fileurl + "";
        const splittedUrl = fileurl.split("/");

        let filename = splittedUrl[splittedUrl.length - 1];
        filename =
          filename.split(".")[0].substr(0, 30) + "__." + filename.split(".")[1];
        let response = await fetch(fileurl);

        let data = await response.blob();

        let metadata = {
          type: data.type,
        };
        let file = new File([data], `${filename}`, metadata);
        this.form.document = file;
        this.documentName = filename;
      }
    },
    submit() {
      this.saving = true;

      this.form.Category = this.form.Category.Category;
      this.form.File_Type = this.form.File_Type.Filetype;
      let data = new FormData();

      for (let formKey in this.form) {
        data.append(formKey, this.form[formKey]);
      }

      this.$repository.document
        .edit(data, this.layer.id)
        .then(() => {
          this.$refs.file.value = null;
          this.$refs.observer.reset();
          this.$toast.success("Layers Updated");
          this.$emit("edited");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$toast.warning(
              "You are not authorized. Please login and try again."
            );
          }
        })
        .finally(() => {
          this.loading = false;
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  width: 100px;
  min-width: 100px;
  overflow: hidden;
}
</style>
